import { onMounted, computed } from "vue";
import { useStore } from "@nuxtjs/composition-api";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  collectionId: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props



const tpl_root = templateRef("top_root");
const tpl_svg = templateRef("tpl_svg");
const store = useStore();
const { reveal } = useScrollTriggerAnimation();

const variant = computed(() => {
  let v;

  if (props.collectionId) {
    //if (store.state.market === "it" || store.state.market === "ww") {
    if (props.collectionId === "2" || props.collectionId === "8")
      v = "aboutwater";
    else if (props.collectionId === "3" || props.collectionId === "9")
      v = "fontanebianche";
    //}
  }

  return v;
});

onMounted(() => {
  if (variant.value) {
    reveal(tpl_svg.value, tpl_root.value, "bottom", {
      intensity: "normal",
      fade: true,
      duration: "normal",
    });
  }
});

return { __sfc: true,props, tpl_root, tpl_svg, store, reveal, variant }
}

}