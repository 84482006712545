import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";

export default {
  __name: 'index',
  props: {
  dataProvider: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props



const { unveilText } = useScrollTriggerAnimation();
const tpl_root = templateRef("tpl_root");
const tpl_title = templateRef("tpl_title");

onMounted(() => {
  if (props.dataProvider.title) {
    unveilText(tpl_title.value, tpl_root.value.$el, "bottom", {
      textTarget: "words",
      intensity: "strong",
      stagger: "wide",
      duration: "normal",
    });
  }
});

return { __sfc: true,props, unveilText, tpl_root, tpl_title }
}

}